<template>
  <div>
    <div>
      <content-header :title="title" :breadList="['模型中心']">
      </content-header>
      <div v-loading="loading">
        <div v-for="(item, index) in list" class="list-box" :key="item.type">
          <div class="list-header">
            <div class="title">
              <i class="el-icon-arrow-down"></i>
              {{ item.type }}
            </div>
          </div>

          <div class="list-content">
            <div
              class="list-content-item"
              v-for="(model, modelIndex) in item.models"
              :key="model.name"
            >
              <img :src="model.Image" class="list-content-item-img" alt="" />
              <div class="list-content-item-right">
                <div class="list-content-item-title" @click="toDetail(model)">
                  {{ model.Name }}
                </div>
                <div class="list-content-item-version">
                  <span>V{{ model.CloudVersion }}</span>
                  <span class="time">{{ model.Time }}</span>
                </div>
                <div class="list-content-item-desc" v-html="model.Desc">
                  <!-- {{ model.Desc }} -->
                </div>
                <div
                  class="list-content-item-button-box"
                  v-if="model.status == 0"
                >
                  <el-button
                    type="primary"
                    round
                    v-if="model.Action === 'Install'"
                    @click="handleInstall(index, modelIndex, model)"
                  >
                    <div class="list-content-item-button">
                      <img src="@/assets/svg/download.svg" alt="" />
                      安装
                    </div>
                  </el-button>
                  <el-button
                    type="primary"
                    round
                    v-if="model.Action === 'Upgrade'"
                    @click="handleInstall(index, modelIndex, model)"
                  >
                    <div class="list-content-item-button">
                      <img src="@/assets/svg/refresh-white.svg" alt="" />
                      更新
                    </div>
                  </el-button>
                  <div class="installed-box" v-if="model.Action === ''">
                    <el-button
                      type="info"
                      round
                      v-if="model.Action === ''"
                      class="installed"
                    >
                      <div class="list-content-item-button installed">
                        <img src="@/assets/svg/done.png" alt="" />
                        已安装
                      </div>
                    </el-button>
                    <!-- <el-button
                      type="default"
                      round
                      v-if="model.Action === ''"
                      class="uninstall"
                      @click="uninstall(index, modelIndex)"
                      >卸载
                    </el-button> -->
                  </div>
                </div>
                <div v-else loading>
                  <el-button
                    type="text"
                    class="update-status"
                    v-if="model.status == 1"
                    >正在检查数据...</el-button
                  >
                  <el-button
                    type="text"
                    class="update-status"
                    v-if="model.status == 2"
                    loading
                    >正在安装...</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import { apiGetModels, apiUploadModel } from "@/api/model-center";
import { apiGetCampaignPermission } from "@/api/auth";
export default {
  components: {
    ContentHeader,
  },
  data() {
    return {
      title: "模型中心列表",
      list: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      package: "common/package",
    }),
    field() {
      const name = "CDPModelType";
      const field = this.package.find((item) => item.APIName === name);
      return field || {};
    },
    categoryList() {
      const list = [];
      if (this.field) {
        (this.field.PicklistOptions || []).forEach((val) => {
          list.push(val);
        });
        return list;
      } else {
        return list;
      }
    },
  },
  watch: {
    categoryList(newVal) {
      if (newVal) {
        this.getList();
      }
    },
  },
  methods: {
    getList() {
      this.loading = true;
      const fetching = [];
      this.categoryList.map((item) => {
        const fetch = apiGetModels({
          type: item,
        });
        fetching.push(fetch);
      });

      Promise.all(fetching)
        .then((res) => {
          let list = [];
          res.map((item, index) => {
            list.push({
              type: this.categoryList[index],
              models: item.models.map((i) => {
                i.status = 0;
                return i;
              }),
            });
          });
          this.list = list;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    toDetail(model) {
      this.$router.push({
        path: "/model-center-yuceping",
        query: {
          id: model.Id,
        },
      });
    },

    // 卸载
    uninstall(index, modelIndex) {
      this.$set(this.list[index].models[modelIndex], "Action", "Install");
    },
    // 下载
    handleInstall(index, modelIndex, model) {
      // 请求编辑权限
      apiGetCampaignPermission(model.Id, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          // 检查数据
          this.$set(this.list[index].models[modelIndex], "status", 1);
          // 正在安装
          setTimeout(() => {
            this.$set(this.list[index].models[modelIndex], "status", 2);
          }, 500);
          apiUploadModel(this.list[index].models[modelIndex].Id)
            .then(() => {
              this.$set(this.list[index].models[modelIndex], "Action", "");
            })
            .finally(() => {
              this.$set(this.list[index].models[modelIndex], "status", 0);
            });
        } else {
          this.$message.error("无编辑权限");
        }
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-button.is-round {
  padding: 8px 18px;
}
/deep/ .el-button--info {
  background-color: #4d4b58;
}
.list-box {
  margin-bottom: 46px;
}
.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding-right: 12px;
  margin-top: -6px;
  margin-bottom: 8px;
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .el-icon-arrow-down {
    margin-right: 10px;
  }
}

.list-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  &-item {
    width: calc(1 / 3 * 100% - 20px);
    height: 215px;
    margin: 10px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
      0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    &-img {
      width: 118px;
      height: 118px;
    }
    &-right {
      margin-left: 18px;
      height: 100%;
    }
    &-title {
      color: #0483ec;
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
      margin-bottom: 8px;
      cursor: pointer;
    }
    &-version {
      font-size: 12px;
      line-height: 19px;
      color: #747378;
      margin-bottom: 8px;
    }
    &-desc {
      font-size: 14px;
      line-height: 22px;
      color: #26262c;
      margin-bottom: 8px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    &-button {
      display: flex;
      align-items: center;
      button {
        padding: 8px 14px;
      }
      img {
        margin-right: 4px;
      }
    }
    .installed-box {
      display: inline-block;
      width: 100px;
    }
    .uninstall {
      width: 100px;
      display: none;
      margin: 0;
    }
    // .installed-box:hover {
    //   .uninstall {
    //     display: block;
    //   }
    //   .installed {
    //     display: none;
    //   }
    // }
  }
}
.update-status {
  font-size: 12px;
  color: #999;
  // margin-top: 8px;
}

.time {
  float: right;
}
</style>
